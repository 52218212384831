html {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-y: scroll;
  overflow-x: hidden; }

.clearfix {
  clear: both;
  zoom: 1; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  visibility: hidden; }

.clearfix:after {
  clear: both; }

body.background.error-page-wrapper,
.background.error-page-wrapper.preview {
  position: relative; }

.background.error-page-wrapper .content-container {
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 50px;
  background-color: #fff;
  width: 100%;
  max-width: 525px;
  position: absolute;
  left: 50%;
  margin-left: -262px; }

.background.error-page-wrapper .content-container.in {
  left: 0px;
  opacity: 1; }

.background.error-page-wrapper .head-line {
  transition: color .2s linear;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1px;
  margin-bottom: 5px;
  color: #ccc; }

.background.error-page-wrapper .subheader {
  transition: color .2s linear;
  font-size: 36px;
  line-height: 46px;
  color: #333; }

.background.error-page-wrapper .hr {
  height: 1px;
  background-color: #ddd;
  width: 60%;
  max-width: 250px;
  margin: 35px auto; }

.background.error-page-wrapper .context {
  transition: color .2s linear;
  font-size: 18px;
  line-height: 27px;
  color: #bbb; }

.background.error-page-wrapper .context p {
  margin: 0; }

.background.error-page-wrapper .context p:nth-child(n+2) {
  margin-top: 16px; }

.background.error-page-wrapper .buttons-container {
  margin-top: 35px;
  overflow: hidden; }

.background.error-page-wrapper .buttons-container a {
  transition: text-indent .2s ease-out, color .2s linear, background-color .2s linear;
  text-indent: 0px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: #2ecc71;
  border-radius: 99px;
  padding: 12px 0 13px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 45%; }

.background.error-page-wrapper .buttons-container .fa {
  transition: left .2s ease-out;
  position: absolute;
  left: -50px; }

.background.error-page-wrapper .buttons-container a:hover {
  text-indent: 15px; }

.background.error-page-wrapper .buttons-container a:nth-child(1) {
  float: left; }

.background.error-page-wrapper .buttons-container a:nth-child(2) {
  float: right; }

.background.error-page-wrapper .buttons-container .fa-home {
  font-size: 18px;
  top: 15px; }

.background.error-page-wrapper .buttons-container a:hover .fa-home {
  left: 10px; }

.background.error-page-wrapper .buttons-container .fa-warning {
  font-size: 16px;
  top: 17px; }

.background.error-page-wrapper .buttons-container a:hover .fa-warning {
  left: 5px; }

.background.error-page-wrapper .buttons-container .fa-power-off {
  font-size: 16px;
  top: 17px; }

.background.error-page-wrapper .buttons-container a:hover .fa-power-off {
  left: 9px; }

.background.error-page-wrapper .buttons-container.single-button {
  text-align: center; }

.background.error-page-wrapper .buttons-container.single-button a {
  float: none !important; }

@media screen and (max-width: 555px) {
  .background.error-page-wrapper {
    padding: 30px 5%; }
  .background.error-page-wrapper .content-container {
    padding: 37px;
    position: static;
    left: 0;
    margin-left: 0; }
  .background.error-page-wrapper .head-line {
    font-size: 36px; }
  .background.error-page-wrapper .subheader {
    font-size: 27px;
    line-height: 37px; }
  .background.error-page-wrapper .hr {
    margin: 30px auto;
    width: 215px; }
  .background.error-page-wrapper .buttons-container .fa {
    display: none; }
  .background.error-page-wrapper .buttons-container a:hover {
    text-indent: 0px; } }

@media screen and (max-width: 450px) {
  .background.error-page-wrapper {
    padding: 30px; }
  .background.error-page-wrapper .head-line {
    font-size: 32px; }
  .background.error-page-wrapper .hr {
    margin: 25px auto;
    width: 180px; }
  .background.error-page-wrapper .context {
    font-size: 15px;
    line-height: 22px; }
  .background.error-page-wrapper .context p:nth-child(n+2) {
    margin-top: 10px; }
  .background.error-page-wrapper .buttons-container {
    margin-top: 29px; }
  .background.error-page-wrapper .buttons-container a {
    float: none !important;
    width: 65%;
    margin: 0 auto;
    font-size: 13px;
    padding: 9px 0; }
  .background.error-page-wrapper .buttons-container a:nth-child(2) {
    margin-top: 12px; } }

.background-image {
  background-color: #FFFFFF;
  background-image: url(https://dka575ofm4ao0.cloudfront.net/assets/better_error_pages/templates/background/background-e1d28eee7cab6e890134c7271be33d66.png) !important; }

.primary-text-color {
  color: #494949 !important; }

.secondary-text-color {
  color: #AAAAAA !important; }

.border-button {
  color: #2ECC71 !important;
  border-color: #2ECC71 !important; }

.button {
  background-color: #2ECC71 !important;
  color: #FFFFFF !important; }

.shadow {
  box-shadow: 0 0 60px #000000; }

footer {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  width: 960px; }
